var Login = function() {

    var handleLogin = function() {
        $('.login-form').validate({
            errorElement: 'span', //default input error message container
            errorClass: 'help-block', // default input error message class
            focusInvalid: false, // do not focus the last invalid input
            rules: {
                email: {
                    required: true,
                    email: true
                },
                password: {
                    required: true
                },
                remember: {
                    required: false
                }
            },

            messages: {
                email: {
                    required: "Email is required."
                },
                
                password: {
                    required: "Password is required."
                }
            },

            //display error alert on form submit   
            invalidHandler: function(event, validator) { 
                $('.alert-danger', $('.login-form')).show();
            },

            highlight: function(element) { // hightlight error inputs
                $(element)
                    .closest('.form-group').addClass('has-error'); // set error class to the control group
            },

            success: function(label) {
                label.closest('.form-group').removeClass('has-error');
                label.remove();
            },

            errorPlacement: function(error, element) {
                error.insertAfter(element.closest('.input-icon'));
            },

            submitHandler: function(form) {
                form.submit(); // form validation success, call ajax form submit
            }
        });
    
        // submit action listeners        
        submitByKeyPressAction($('.login-form'));
        submitFormAction($('.login-form'));      
    }
    
    var handleForgetPassword = function() {
        $('.forget-form').validate({
            errorElement: 'span', //default input error message container
            errorClass: 'help-block', // default input error message class
            focusInvalid: false, // do not focus the last invalid input
            ignore: "",
            rules: {
                email: {
                    required: true,
                    email: true
                }
            },

            messages: {
                email: {
                    required: "Email is required."
                }
            },

            invalidHandler: function(event, validator) { //display error alert on form submit   

            },

            highlight: function(element) { // hightlight error inputs
                $(element)
                    .closest('.form-group').addClass('has-error'); // set error class to the control group
            },

            success: function(label) {
                label.closest('.form-group').removeClass('has-error');
                label.remove();
            },

            errorPlacement: function(error, element) {
                error.insertAfter(element.closest('.input-icon'));
            },

            submitHandler: function(form) {
                form.submit();
            }
        });

        // submit action listeners        
        submitByKeyPressAction($('.forget-form'));
        submitFormAction($('.forget-form'));      
    }

    var handleResetPassword = function() {

        $('.reset-form').validate({
            errorElement: 'span', //default input error message container
            errorClass: 'help-block', // default input error message class
            focusInvalid: false, // do not focus the last invalid input
            ignore: "",
            rules: {

                email: {
                    required: true,
                    email: true
                },

                password: {
                    required: true
                },
               
                password_confirmation: {
                    equalTo: "#register_password"
                },
            },

            invalidHandler: function(event, validator) { //display error alert on form submit   

            },

            // hightlight error inputs
            highlight: function(element) { 
                $(element)
                    .closest('.form-group').addClass('has-error'); // set error class to the control group
            },

            success: function(label) {
                label.closest('.form-group').removeClass('has-error');
                label.remove();
            },

            errorPlacement: function(error, element) {
                if (element.attr("name") == "tnc") { // insert checkbox errors after the container                  
                    error.insertAfter($('#register_tnc_error'));
                } else if (element.closest('.input-icon').size() === 1) {
                    error.insertAfter(element.closest('.input-icon'));
                } else {
                    error.insertAfter(element);
                }
            },

            submitHandler: function(form) {
                form.submit();
            }
        });

        // submit action listeners        
        submitByKeyPressAction($('.reset-form'));
        submitFormAction($('.reset-form'));
    }    

    var handleRegister = function() {

        $('.register-form').validate({
            errorElement: 'span', //default input error message container
            errorClass: 'help-block', // default input error message class
            focusInvalid: false, // do not focus the last invalid input
            ignore: "",
            rules: {
                email: {
                    required: true,
                    email: true
                },
                password: {
                    required: true
                },
                password_confirmation: {
                    required : true,
                    equalTo: "#register_password"
                },
                tnc:{
                    required:true,
                },
            },

            //display error alert on form submit   
            invalidHandler: function(event, validator) { 
            },

            highlight: function(element) { // hightlight error inputs
                $(element)
                    .closest('.form-group').addClass('has-error'); // set error class to the control group
            },

            success: function(label) {
                label.closest('.form-group').removeClass('has-error');
                label.remove();
            },

            errorPlacement: function(error, element) {
                if (element.attr("name") == "tnc") { // insert checkbox errors after the container                  
                    error.insertAfter($('#register_tnc_error'));
                } else if (element.closest('.input-icon').size() === 1) {
                    error.insertAfter(element.closest('.input-icon'));
                } else {
                    error.insertAfter(element);
                }
            },

            submitHandler: function(form) {
                form.submit();
            }
        });

        $('#email').on('change', function(){
            var self = $(this);

            if ( self.valid() == false) {
                return false;
            }                

            validateIfEmailExist(self);
        });

        // submit action listeners        
        submitByKeyPressAction($('.register-form'));
        submitFormAction($('.register-form'));
    }

    /**
     * make a ajax request to validate if provided email exist
     */
    var validateIfEmailExist = function ( emailInput )
    {
        // $('#email').addClass('spinner');
        var submitBtn       = $('#register-submit-btn');
        var emailFormGroup  = $('#email-form-group');
        var alertNotice     = $('.alert-danger', $('.register-form'));

        emailInput.addClass('spinner');
        submitBtn.button('loading');


        var data = { 
            'email'     : emailInput.val(),
            '_token'    : $('.register-form').children('input[name="_token"]').val()
        }


        $.ajax({
            method: 'POST',
            url: '/register/check/email' ,
            data: data
        })

        .done( function( msg, textStatus ) {
            // remove any alert notes 
            alertNotice.hide();
            alertNotice.children('span').remove();    

            // unblock next and back btn
            submitBtn.button('reset');
            emailInput.removeClass('spinner');

            emailFormGroup.removeClass('has-error');
            emailFormGroup.find('i.iconStatus').removeClass('fa-warning');
            emailFormGroup.find('i.iconStatus').addClass('fa-check');
            emailFormGroup.addClass('has-success');
        })

        .fail(function( msg, textStatus  ) {
            // admin@creativesciencelabs.com
            // show errors messages
            var errorOUtput;
            ajaxErrors = JSON.parse(msg.responseText);
            
            errorOUtput = "<ul>";                        
            // loop through the errors to be output.    
            for (var prop in ajaxErrors) {
                errorOUtput += "<li>" + ajaxErrors[prop][0] + "</li>";
            }

            errorOUtput += "</ul>";

            $(errorOUtput).appendTo(alertNotice);

            alertNotice.show()

            
            $('.alert-danger', $('.register-form')).show();
            emailFormGroup.removeClass('has-success');
            emailInput.removeClass('spinner');
            emailFormGroup.addClass('has-error');
            emailFormGroup.find('i.iconStatus').removeClass('fa-check');
            emailFormGroup.find('i.iconStatus').addClass('fa-warning');
            
        });                
    }    

    var submitFormAction = function(currentForm, submitBnt){

        submitBnt = submitBnt || $('.submitBtn');
    
        if(currentForm.length){
    
            submitBnt.on('click', function(e){
                
                e.preventDefault();
                fmLoadingIcon.init('start', submitBnt, 'fa-arrow-circle-o-right', 'fa-spinner fa-pulse fa-fw', true);
                
                if (currentForm.validate().form()) {
                    return currentForm.submit(); //form validation success, call ajax form submit
                }
                
                fmLoadingIcon.init('end', submitBnt, false, 'fa fa-arrow-circle-o-right', true);
                return false;
            });          
            
        }
    }

    var submitByKeyPressAction = function(form, submitBtn){

        submitBtn = submitBtn || $('.submitBtn');

        // $('.reset-form input').keypress(function(e) {
        form.find('input').keypress(function(e) {
            if (e.which == 13) {
                
                fmLoadingIcon.init('start', submitBtn, 'fa-arrow-circle-o-right', 'fa-spinner fa-pulse fa-fw', true);

                if (form.length && form.validate().form()) {
                    return form.submit();
                }
                
                fmLoadingIcon.init('end', submitBtn, false, 'fa fa-arrow-circle-o-right', true);
                return false;
            }
        });
    }

    return {
        //main function to initiate the module
        init: function() {

            handleLogin();
            handleForgetPassword();
            handleResetPassword();
            handleRegister();

        }
    };

}();